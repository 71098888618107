.actions {
  position: fixed;
  display: flex;
  overflow-x: auto;
  background: #D9D9D9;
  z-index: 10;
  bottom: 10px;
  padding-bottom: 4px;
  right: 20px;

  @media screen and (max-width: 599px) {
    width: 100% !important;
  }

  &.menuCollapsed {
    width: calc(100% - 88px);
  }

  &.menuExpand {
    width: calc(100% - 264px);
  }
}

@primary-color: #ef4721;@font-size-base: 14px;@theme: dark;@border-color-base: #ccc;@divider-color: #ccc;